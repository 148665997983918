











































/* ----------------------------------------
    Imports
---------------------------------------- */

import { Component, Vue } from 'vue-property-decorator';
import { app } from '@config';
import { mapState } from 'vuex';
import PageMain from '@part/layout/PageMain.vue';
import Checkbox from '@part/elements/Checkbox.vue';
import lang from '@lang/shipping.js';

/* ----------------------------------------
    Component
---------------------------------------- */

@Component({
    components: {
        PageMain,
        Checkbox
    },
    props: [],
    data() {
        return {
            shippingData: {
                name: '',
                email: '',
                street: '',
                house_no: '',
                house_additional: '',
                postal: '',
                city: '',  
                newsletter: 0
            },
            terms: false,
            filledIn: false,
            required: {
                name: false,
                email: false,
                street: false,
                house_no: false,
                postal: false,
                city: false,
                terms: false
            },
            error: ''
        };
    },
    computed: {
        lang(){
            return lang[this.activeLang()];
        },
        ...mapState(['sessionData']),
        filled() {
            let filledIn = true
            for (let key in this.required) {
                !this.required[key] && (filledIn = false)
            }
            return filledIn;
        }
    },
    methods: {
        setAllData(){
            for (let key in this.shippingData) {
                this.setData(key, this.shippingData[key])
            }

            setTimeout(() => {
                this.$router.push('/age')
            }, 300);
        },
        toggleInfo() {
            this.toggleOverlay(true);
        },
        toggleTerms(key) {
            this.terms = !this.terms;
            this.terms ? this.required.terms = true : this.required.terms = false;
        },
        toggleCheckbox(key) {
            this.shippingData[key] = this.shippingData[key] == 1 ? 0 : 1;
        },
        checkRequired(key) {
            this.shippingData[key] ? this.required[key] = true : this.required[key] = false;
        },
        validate(){
            const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            const postalRegex = /\d/; // has to contain numbers
            const houseNoRegex = /^\d+$/; // only numbers
            let validEmail = emailRegex.test(String(this.shippingData.email).toLowerCase());
            let validPostal = postalRegex.test(this.shippingData.postal);
            let validHouseNo = houseNoRegex.test(this.shippingData.house_no);

            if (validEmail && validPostal && validHouseNo) {
                this.setAllData();
                this.putSessionData(this.sessionData.order_id, this.sessionData)
                return;
            } else {
                let errorIn = '';
                !validPostal && (errorIn = this.lang.postal.toLowerCase());
                !validHouseNo && (errorIn = this.lang.number.toLowerCase());
                !validEmail && (errorIn = this.lang.email.toLowerCase());
                if (this.sessionData.lang == 'en') {
                    this.error = `Please check the ${errorIn} field.`
                } else {
                    this.error = `Please check the ${errorIn} field.`
                }
            }
        }
    },
    mounted() {}
})

/* ----------------------------------------
    Export
---------------------------------------- */
export default class Dev extends Vue {}
