



















/* ----------------------------------------
    Imports
---------------------------------------- */

import { Component, Vue } from 'vue-property-decorator';


/* ----------------------------------------
    Component
---------------------------------------- */

@Component({
    components: {
    },
    props: ['label', 'checked', 'link'],
    data() {
        return {}
    },
    methods: {
    }
})

/* ----------------------------------------
        Export
    ---------------------------------------- */

export default class Checkbox extends Vue {};

