export default {
    en: {
        title: 'ADDRESS',
        info: {
            sock: 'Please fill in a Dutch address and we’ll send you your socks. Would you like to send it abroad? Please get an employee to assist you.',
            scarf: 'Please fill in a Dutch address and your scarf will be sent to you soon. Would you like to send it abroad? Ask an employee to help you.'
        },
        name: 'Name + surname',
        email: 'Email',
        street: 'Street',
        number: 'Number',
        other: 'Apt / suite / other',
        postal: 'Postal Code',
        city: 'City',
        terms1: 'I accept the ',
        terms2: 'terms and conditions',
        newsletter: 'I would like to subscribe to the newsletter',
        button: 'CONTINUE '
    },
    nl: {
        title: 'ADRESGEGEVENS',
        info: {
            sock: 'Wil je ‘m naar het buitenland sturen? Haal er dan even een medewerker bij die je verder helpt.',
            scarf: 'Vul een Nederlands adres in en je sjaal valt binnen no-time op de mat. Wil je ‘m naar het buitenland sturen? Haal er dan even een medewerker bij die je verder helpt.'
        },
        name: 'Voornaam + achternaam',
        email: 'E-mail',
        street: 'Straat',
        number: 'Huisnummer',
        other: 'Toevoeging',
        postal: 'Postcode',
        city: 'Stad',
        terms1: 'Ik accepteer de ',
        terms2: 'algemene voorwaarden',
        newsletter: 'Ik wil me graag aanmelden voor de nieuwsbrief',
        button: 'DOORGAAN '
    }
};
